<template>
  <div id="app" class="wrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
  @import "./assets/css/main.css";
</style>
