import Vue from 'vue'
import router from 'vue-router'

Vue.use(router)

export default new router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      //redirect: '/login'
      component: resolve => require(['../components/index/Index.vue'], resolve)
    },
  
    {
      path: '/login',
      //redirect: '/'
      component: resolve => require(['../components/user/Login.vue'], resolve)
    },
    {
      path: '/course',
      component: resolve => require(['../components/course/Index.vue'], resolve)
    },
    {
      path: '/coursedetail',
      component: resolve => require(['../components/course/CourseDetail.vue'], resolve)
    },
    {
      path: '/category',
      component: resolve => require(['../components/course/Category.vue'], resolve)
    },
    {
      path: '/video',
      component: resolve => require(['../components/course/Video.vue'], resolve)
    },
    {
      path:'/steppic',
      component: resolve => require(['../components/course/StepPic.vue'], resolve)
    },
    {
      path:'/bind',
      component: resolve => require(['../components/user/Bind.vue'], resolve)
    },
    {
      path:'/authback',
      component: resolve => require(['../components/user/AuthBack.vue'], resolve)
    },
    {
      path:'/404',
      component: resolve => require(['../components/index/404.vue'], resolve)
    },
    // {
    //   path: '/home',
    //   component: resolve => require(['../components/Home.vue'], resolve),
    //   children:[
    //     {
    //       path: '/',
    //       component: resolve => require(['../components/Index.vue'], resolve)
    //     },
    //     {
    //       path: '/users',
    //       component: resolve => require(['../components/user/Users.vue'], resolve)
    //     },
    //     {
    //       path: '/user/info',
    //       component: resolve => require(['../components/user/UserInfo.vue'], resolve)
    //     }
    //   ]
    // }
  ]
})