import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
Vue.prototype.conWidth = window.innerWidth;
Vue.prototype.$isWeixin = ()=> {
  return "micromessenger" == navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
};
Vue.prototype.getExploreName = function () {
  var e = navigator.userAgent;
  return e.indexOf("Opera") > -1 || e.indexOf("OPR") > -1 ? "Opera" : e.indexOf("compatible") > -1 && e.indexOf("MSIE") > -1 ? "IE" : e.indexOf("Edge") > -1 ? "Edge" : e.indexOf("Firefox") > -1 ? "Firefox" : e.indexOf("Safari") > -1 && -1 == e.indexOf("Chrome") ? "Safari" : e.indexOf("Chrome") > -1 && e.indexOf("Safari") > -1 ? "Chrome" : window.ActiveXObject || "ActiveXObject" in window ? "IE>=11" : "Unkonwn"
};
Vue.prototype.getBrowser = function () {
  var e = navigator.userAgent.toLowerCase()
    , t = null
    , o = {
      IE: window.ActiveXObject || "ActiveXObject" in window,
      Chrome: e.indexOf("chrome") > -1 && e.indexOf("safari") > -1,
      Firefox: e.indexOf("firefox") > -1,
      Opera: e.indexOf("opera") > -1,
      Safari: e.indexOf("safari") > -1 && -1 == e.indexOf("chrome"),
      Edge: e.indexOf("edge") > -1,
      QQBrowser: /qqbrowser/.test(e),
      WeixinBrowser: /MicroMessenger/i.test(e)
    };
  for (var n in o)
    o[n] && (t = n);
  return t
};
Vue.prototype.getOsInfo = function () {
  var e = navigator.userAgent.toLowerCase()
    , t = "Unknown"
    , o = "Unknown";
  return e.indexOf("win") > -1 ? (t = "Windows",
    o = e.indexOf("windows nt 5.0") > -1 ? "Windows 2000" : e.indexOf("windows nt 5.1") > -1 || e.indexOf("windows nt 5.2") > -1 ? "Windows XP" : e.indexOf("windows nt 6.0") > -1 ? "Windows Vista" : e.indexOf("windows nt 6.1") > -1 || e.indexOf("windows 7") > -1 ? "Windows 7" : e.indexOf("windows nt 6.2") > -1 || e.indexOf("windows 8") > -1 ? "Windows 8" : e.indexOf("windows nt 6.3") > -1 ? "Windows 8.1" : e.indexOf("windows nt 6.2") > -1 || e.indexOf("windows nt 10.0") > -1 ? "Windows 10" : "Unknown") : t = e.indexOf("iphone") > -1 ? "Iphone" : e.indexOf("mac") > -1 ? "Mac" : e.indexOf("x11") > -1 || e.indexOf("unix") > -1 || e.indexOf("sunname") > -1 || e.indexOf("bsd") > -1 ? "Unix" : e.indexOf("linux") > -1 ? e.indexOf("android") > -1 ? "Android" : "Linux" : "Unknown",
  {
    name: t,
    version: o
  }
};
Vue.prototype.isMobile = function () {
  return navigator.userAgent.match(/(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
};
Vue.prototype.isPad = function () {
  return navigator.userAgent.match(/(iPad)/i)
};

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
